<template>
  <div class="dgsa-list">
    <div class="table-list">
      <el-tabs v-model="form.ftype" type="card" @tab-click="handleClick">
        <el-tab-pane
            v-for="item in serve_list"
            :key="item.ftype"
            :label="item.title"
            :name="item.ftype"
        ></el-tab-pane>
      </el-tabs>
      <div v-if="form.ftype === '6'"><helpDesk></helpDesk></div>
      <div v-else>
        <div class="function-btn">
          <div class="function-add">
            <el-button type="primary" @click="addEdit(false)" size="small"
            >新建文件
            </el-button
            >
            <el-button :loading="exportListLoading" type="primary" @click="exportList" size="small"
            >导出表格
            </el-button
            >
            <el-checkbox class="batch" v-model="batch">批量操作</el-checkbox>
            <el-button :disabled="!batch" :loading="batchDownloadLoading" type="primary" @click="batchDownload" size="small"
            >下载
            </el-button
            >
          </div>
          <el-input
              style="width: 334px"
              :placeholder="
              form.ftype === '1'
                ? '输入文件名称/产品编号/产品名称'
                : '输入文件名称'
            "
              v-model="form.search_name"
              clearable
              size="small"
          >
            <el-button
                slot="append"
                @click="updateList"
                icon="el-icon-search"
            ></el-button>
          </el-input>
        </div>
        <div class="table-value">
          <el-table
              :data="tableData"
              style="width: 100%"
              :height="542"
              :header-cell-style="headClass"
              v-tableHeight="{ bottomOffset: 74 }"
              :tableLoading="loading"
              ref="tableName"
              @sort-change="sortBtn"
              @selection-change="handleSelectionChange"
          >
            <el-table-column
                v-if="batch"
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="product_no"
                label="产品编号"
                min-width="130px"
                v-if="form.ftype === '1'"
            >
            </el-table-column>
            <el-table-column
                prop="product_name"
                label="产品名称"
                min-width="130px"
                v-if="form.ftype === '1'"
            >
            </el-table-column>
            <el-table-column prop="file_name" label="文件名称" min-width="130px">
            </el-table-column>
            <el-table-column
                prop="created_time"
                label="上传时间"
                min-width="130px"
            >
              <div slot-scope="scope">
                {{ scope.row.created_time | secondFormat }}
              </div>
            </el-table-column>
            <el-table-column
                prop="download_num"
                label="下载量(次)"
                sortable="custom"
                min-width="130px"
            >
            </el-table-column>
            <el-table-column label="操作" min-width="130px">
              <template slot-scope="scope">
                <el-button
                    type="text"
                    size="small"
                    class="all-btn-css"
                    @click="moduleDownload(scope.row)"
                >下载
                </el-button
                >
                    <!-- v-if="user_id == scope.row.creater" -->
                <el-button
                    v-if="user_id == scope.row.creater"
                    class="user-btn-css"
                    type="text"
                    size="small"
                    @click="addEdit(true, scope.row.id)"
                >编辑
                </el-button
                >
                    <!-- v-if="user_id == scope.row.creater" -->
                <el-button
                    class="user-btn-css"
                    type="text"
                    size="small"
                    @click="deleteItem(scope.row.id)"
                >删除
                </el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-block">
          <el-pagination
              @current-change="handleChange"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <downloadDialog
        :dialogVisible="dialogVisible"
        :downloadData="downloadData"
        @closeBtn="closeBtn"
    />
  </div>
</template>

<script>
import downloadDialog from "~gbk/components/dasa/downloadDialog";
import helpDesk from "./helpDesk";


export default {
  name: "dgsaList",
  metaInfo: {
    title: "SDS管理",
  },
  components: {
    downloadDialog,
    helpDesk
  },
  data() {
    return {
      batch:false,
      batchDownloadLoading:false,
      exportListLoading:false,
      batchList:[],
      activeFtype: "1",
      serve_list: [
        {
          title: "产品管理",
          ftype: "1",
        },
        {
          title: "培训",
          ftype: "2",
        },
        {
          title: "审核清单",
          ftype: "3",
        },
        {
          title: "咨询报告",
          ftype: "4",
        },
        {
          title: "法规标准",
          ftype: "5",
        },
        {
          title: "咨询台helpdesk",
          ftype: "6",
        },
      ], //服务列表
      form: {
        ftype: "1",
        order_by: "",
        search_name: "",
        limit: 10,
        start: 1,
      },
      tableData: [],
      loading: false,
      total: 0,
      dialogVisible: false, //下载
      downloadData: {documentList: [], imgList: [], id: ""}, //下载数据
      user_id: "",
    };
  },
  mounted() {
    this.user_id = this.USER_INFO.id;
    this.form.ftype = this.$route.query.ftype ? this.$route.query.ftype : "1";
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true;
      try {
        let params = Object.assign({}, this.form);
        params.start -= 1;
        // params.user_id = this.user_id
        params.operator = this.user_id
        let res = await this.$store.dispatch(
            "API_certificate/getDgaFilesList",
            params
        );
        if (res.success) {
          this.tableData = res.data;
          this.total = res.count;
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
    // 更新数据
    updateList() {
      if (!this.USER_INFO) {
        this.$router.push({
          path: "/sign",
        });
        return false;
      }
      this.form.start = 1;
      this.getList();
    },
    //文件类型选择
    handleClick(tab, event) {
      if(this.form.ftype==6) {
        return
      }
      this.form.search_name = "";
      this.form.start = 1;
      this.batch=false
      this.getList();
      this.$nextTick(() => {
        this.$refs.tableName.doLayout();
      });
    },
    // 表头颜色
    headClass() {
      return "background: #F7F8FA;";
    },
    handleChange(e) {
      this.form.start = e;
      this.getList();
    },
    sortBtn(e) {
      if (e.order === "ascending") {
        this.form.order_by = "1";
      } else if (e.order === "descending") {
        this.form.order_by = "2";
      } else {
        this.form.order_by = "";
      }
      this.getList();
    },
    // 添加编辑
    addEdit(bol, id) {
      if (!this.USER_INFO) {
        this.$router.push({
          path: "/sign",
        });
        return false;
      }
      if (bol) {
        this.$router.push(
            `/SDSFileUpload?type=${this.form.ftype}&edit=${true}&id=${id}`
        );
      } else {
        this.$router.push(
            `/SDSFileUpload?type=${this.form.ftype}&edit=${false}`
        );
      }
    },
    // 删除
    deleteItem(id) {
      this.$confirm("删除后无法恢复,确认删除？", "删除", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(async () => {
        let params = {
          dga_file_id: id,
          deleted: 1,
          user_id: this.user_id
        };
        let res = await this.$store.dispatch(
            "API_certificate/uploadFileEdit",
            params
        );
        if (res.success) {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        }
      })
      .catch(() => {
      });
    },
    // 模块内容下载
    moduleDownload(value) {
      if (!this.USER_INFO) {
        this.$router.push({
          path: "/sign",
        });
        return false;
      }
      this.downloadData = {documentList: [], imgList: [], id: value.id};
      let url_list = value.file_url ? JSON.parse(value.file_url) : [];
      url_list.forEach((item) => {
        if (item.type === "img") {
          this.downloadData.imgList.push(item);
        } else {
          this.downloadData.documentList.push(item);
        }
      });
      this.dialogVisible = true;
    },
    closeBtn(bol) {
      if (bol) {
        this.getList();
      }
      this.dialogVisible = false;
    },
    //导出表格
    async exportList(){
      this.exportListLoading=true
      let params={}
      params.ftype=this.form.ftype
      params.order_by=this.form.order_by
      params.search_name=this.form.search_name
      params.user_id=this.user_id
      let res=await this.$store.dispatch('API_certificate/dgsaPageFtypeExportExcelList',params)
      if (res.success) {
        this.exportListLoading=false
        // window.open(res.data)
        window.open('','_self').location.href = res.data
      }

    },
    handleSelectionChange(val){
      this.batchList=val
    },
    async batchDownload(){
      if (!this.batchList.length) {
        this.$message.warning('请选择数据')
        return
      }
      this.batchDownloadLoading=true
      let params={}
      // .join(",")
      let ids=this.batchList.map(item=>item.id)
      params.file_ids=JSON.stringify(ids)
      let res=await this.$store.dispatch("API_certificate/dgsaBatcgDownloadAll",params)
      if (res.success) {
        this.batchDownloadLoading=false
        window.open('','_self').location.href = res.data
        if (res.errors.length) {
          let arr=res.errors.map(item=>item.product_name).join(",")
          this.$message.warning(`产品名称${arr}下载失败`)
        }
      }
    }
  },
};
</script>

<style scoped lang="less">
.dgsa-list {
  background: #f6f7f9;
  padding-top: 22px;

  .table-list {
    background: #ffffff;
    width: 1200px;
    margin: 0 auto;
    padding: 25px 31px;

    /deep/ .el-tabs__item {
      background: #f7f8fa;
      width: 131px;
      text-align: center;
      border-color: #e6e8ea;
    }

    /deep/ .el-tabs__item:hover {
      color: #323334;
    }

    /deep/ .el-tabs__item.is-active {
      color: #323334;
      background: #ffffff;
      border-color: transparent;
    }

    .function-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .function-add {
        display: flex;
        align-items: center;

        .function-select {
          width: 100px;
          margin-left: 20px;
        }
      }
    }

    .table-value {
      margin-top: 26px;
    }

    .pagination-block {
      text-align: right;
      margin-top: 25px;
    }

    .all-btn-css {
      color: #116bff;
    }

    .user-btn-css {
      color: #686a6d;
    }
  }
}
.batch{
  margin: 0 10px;
}
</style>
<template>
  <div class="help-desk">
    <div class="emailForm" v-if="submitshow">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-position="left" class="demo-ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="ruleForm.name" clearable></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input v-model="ruleForm.phone" clearable></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input v-model="ruleForm.email" disabled></el-input>
        </el-form-item>
        <el-form-item label="提问主题" prop="title">
          <el-input v-model="ruleForm.title" clearable></el-input>
        </el-form-item>
          <el-form-item label="问题描述" prop="content">
            <richText ref="richText" :width="1200" :height="250"/>
          </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="submitOk" v-else>
      <div class="demo-image">
        <el-image
          style="width: 199px; height: 199px"
          :src="url"
          :fit="fit"></el-image>
      </div>
      <div class="warnTitle">提交成功</div>
      <div class="warnText">谢谢您的问题，您的问题已经发送给GBK 安全顾问，咨询人员马上会通过邮件以及电话方式联系您</div>
      <el-button class="btn" type="primary" @click="emailBack">返回</el-button>
    </div>
  </div>
</template>
<script>
import richText from "@/baseComponents/richText";
import {  validateTelephone } from "~/baseUtils/validate";
  export default {
    components: { richText },
    mounted() {},
    data() {
      return {
        submitshow: true,
        url: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/gbk/DGSA/information.png',
        ruleForm: {
          name: '',
          phone: '',
          email: 'chenfengshen@gbk-china.com',
          title: '',
          content: '',
          user_id:'',
          source:''
        },
        rules: {
          name: [
            { required: true, message: '请输入名字', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: "blur" },
            { 
              validator: async (rule, value, callback) => {
                if (!validateTelephone(this.ruleForm.phone)) {
                  callback(new Error("请输入正确的手机号码格式"));
                } else {
                  callback();
                }
              },
              trigger: "blur"
            },
          ],
          
          title: [
            { required: true, message: '请输入提问主题', trigger: 'blur' },

          ],
          content: [
            { required: true, message: '请输入问题描述', trigger: 'blur' },
          ],
        }
      };
    },
    methods: {
      submitForm(formName) {
        this.ruleForm.user_id = this.USER_INFO.id
        this.ruleForm.content = this.$refs.richText.getContent()
        let params = this.ruleForm
        console.log(this.ruleForm)
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            // this.ruleForm.user_id = 
            this.ruleForm.source = this.PJSource
            let res = await  this.$store.dispatch("API_certificate/sendEmail", params);
            if (res.success) {
              this.$message({
                message: "提交成功",
                type: "success",
              });
              this.ruleForm.name = '',
              this.ruleForm.phone = '',
              this.ruleForm.title = '',
              this.ruleForm.content = ''
              this.submitshow = false
            }
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      emailBack() {
        this.submitshow = true
      }
    },
  }
</script>
    
<style lang="less" scoped>
.emailForm {
  width: 810px;
  height: 700px;
  
  margin-top: 15px;
  .el-form-item {
    margin-bottom: 32px;
  }
}
.submitOk{
  height: 700px;
  margin-top: 15px;
  padding-top: 100px;
  .demo-image {
      width: 199px;
      height: 199px;
      margin: 0 auto;
  }
  .warnTitle {
    font-size: 20px;
    font-weight: 100;
    text-align: center;
    margin: 31px 0 20px 0;
  }
  .warnText {
    width: 411px;
    height: 38px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 240px;
  }
  .btn{
    margin-left: 100px;
  }
}
</style>